import type { Callback } from 'ts/base/Callback';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import { DashboardSaveDialogBase } from './DashboardSaveDialogBase';

/** A dialog for saving a dashboard template. */
export class DashboardTemplateSaveDialog extends DashboardSaveDialogBase<DashboardTemplateDescriptor> {
	/**
	 * @param client The service client to use.
	 * @param dashboardDescriptor The descriptor of the old dashboard.
	 * @param successCallback The callback which will be invoked on a successful save. The given parameter is the new
	 *   name of the saved dashboard.
	 * @param askForOverride Whether or not to ask when overriding a dashboard.
	 * @param createMode Whether a new dashboard template should be created
	 */
	public constructor(
		client: TeamscaleServiceClient,
		dashboardDescriptor: DashboardTemplateDescriptor,
		successCallback?: Callback<string>,
		askForOverride?: boolean | null,
		createMode?: boolean
	) {
		super(client, dashboardDescriptor, true, true, successCallback, askForOverride, createMode, true);
	}

	public override async doSave(
		createMode: boolean,
		dashboardDescriptor: DashboardTemplateDescriptor,
		successCallback: Callback<string>,
		nameAlreadyExists: boolean,
		oldDashboardName: string
	): Promise<void> {
		const newQualifiedName = dashboardDescriptor.owner + '/' + dashboardDescriptor.name;
		if (createMode && nameAlreadyExists) {
			await this.client.deleteTemplate(newQualifiedName);
		}
		if (createMode) {
			await this.client.createDashboardTemplate(dashboardDescriptor);
		} else {
			const oldTemplateName = dashboardDescriptor.owner + '/' + oldDashboardName;
			await this.client.saveDashboardTemplate(oldTemplateName, dashboardDescriptor);
		}
		successCallback(newQualifiedName);
	}

	public override descriptorNameAlreadyExists(qualifiedName: string): Promise<boolean> {
		return this.client.dashboardTemplateExists(qualifiedName);
	}

	public override setDescriptorDescription(description: string): void {
		this.dashboardDescriptor.description = description;
	}

	public override getDescriptionFromDescriptor(): string {
		return this.dashboardDescriptor.description;
	}
}
