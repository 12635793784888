import * as TeamscaleDashboardPerspectiveTemplate from 'soy/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy.generated';
import * as forms from 'ts-closure-library/lib/dom/forms';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import type { Event } from 'ts-closure-library/lib/events/event';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { DefaultButtons, Dialog, EventType as DialogEventType } from 'ts-closure-library/lib/ui/dialog';
import type { Callback, CallbackWithTwoParameters } from 'ts/base/Callback';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import * as soy from 'ts/base/soy/SoyRenderer';
import { ConfirmActionWithString } from 'ts/commons/dialog/ConfirmActionWithString';
import { UIUtils } from 'ts/commons/UIUtils';
import { Validator } from 'ts/commons/Validator';
import type { DashboardDescriptorBase } from 'typedefs/DashboardDescriptorBase';
import { DashboardUtils } from './DashboardUtils';

/** A base class for showing a dialog for saving a dashboard descriptor. */
export abstract class DashboardSaveDialogBase<T extends DashboardDescriptorBase> extends Dialog {
	/**
	 * @param client The service client to use.
	 * @param dashboardDescriptor The descriptor of the old dashboard or dashboard template.
	 * @param allowNameChange Whether or not to allow name changes
	 * @param allowOverride Whether or not to allow overriding existing dashboards
	 * @param successCallback The callback which will be invoked on a successful save. The given parameter is the new
	 *   name of the saved dashboard.
	 * @param askForOverride Whether or not to ask when overriding a dashboard.
	 * @param createMode If a new dashboard should be created or existing one edited
	 */
	protected constructor(
		protected client: TeamscaleServiceClient,
		protected dashboardDescriptor: T,
		private readonly allowNameChange: boolean,
		private readonly allowOverride: boolean,
		private readonly successCallback?: CallbackWithTwoParameters<string, string[]>,
		private readonly askForOverride?: boolean | null,
		private readonly createMode = false,
		private readonly isTemplate = false
	) {
		super();
	}

	/** Opens the save dialog. */
	public show(): void {
		this.setButtonSet(UIUtils.createSaveCancelButtonSet());
		const parameters = {
			allowNameChange: this.allowNameChange,
			dashboardName: this.dashboardDescriptor.name,
			dashboardGroup: this.dashboardDescriptor.group,
			description: this.getDescriptionFromDescriptor(),
			isTemplate: this.isTemplate
		};
		const dialogContent = soy.renderAsElement(
			TeamscaleDashboardPerspectiveTemplate.saveDashboardDialogContent,
			parameters
		);
		this.getContentElement()!.appendChild(dialogContent);
		this.setDisposeOnHide(true);
		this.setVisible(true);
		const dashboardNameElement = document.getElementById('dashboard-name') as HTMLInputElement;
		const descriptionElement = document.getElementById('change-description') as HTMLInputElement;
		let groupElement: HTMLInputElement;
		if (!this.isTemplate) {
			groupElement = document.getElementById('dashboard-group') as HTMLInputElement;
		}
		if (this.allowNameChange) {
			dashboardNameElement.focus();
		} else {
			descriptionElement.focus();
		}
		events.listen(this, DialogEventType.SELECT, dialogEvent =>
			this.oneSaveButtonPressed(dashboardNameElement, descriptionElement, groupElement, dialogEvent)
		);
	}

	/**
	 * Handles the save button select event of the dialog.
	 *
	 * @param dashboardNameElement Input element for the dashboard name.
	 * @param descriptionElement Input element for the dashboard description.
	 * @param dialogEvent The event object from the dialog.
	 */
	private oneSaveButtonPressed(
		dashboardNameElement: Element,
		descriptionElement: Element,
		groupElement: Element | undefined,
		dialogEvent: BrowserEvent
	): void {
		if (dialogEvent.key === DefaultButtons.SAVE.key) {
			const oldName = this.dashboardDescriptor.name!;
			this.dashboardDescriptor.name = String(forms.getValue(dashboardNameElement));
			this.setDescriptorDescription(String(forms.getValue(descriptionElement)));
			this.dashboardDescriptor.group = groupElement ? String(forms.getValue(groupElement)) : '';
			dialogEvent.preventDefault();
			const projects = DashboardUtils.getReferencedProjects(this.dashboardDescriptor);
			this.performSave(
				this.dashboardDescriptor,
				dialogEvent,
				() => {
					this.successCallback?.(
						DashboardUtils.buildQualifiedDashboardName(
							this.dashboardDescriptor.owner,
							this.dashboardDescriptor.name!
						),
						projects
					);
					this.dispose();
				},
				oldName
			);
		}
	}

	/**
	 * Performs the actual saving.
	 *
	 * @param dashboardDescriptor The dashboard descriptor to save
	 * @param dialogEvent The event that caused the save.
	 * @param successCallback The callback to call when we have successfully saved the dashboard.
	 * @param oldDashboardName Old name is necessary in case a renaming has been done
	 */
	private async performSave(
		dashboardDescriptor: T,
		dialogEvent: Event,
		successCallback: Callback<string>,
		oldDashboardName: string
	) {
		const validator = new Validator(this.getContentElement());
		const dashboardQualifiedName = DashboardUtils.buildQualifiedDashboardName(
			dashboardDescriptor.owner,
			dashboardDescriptor.name!
		);
		if (!validator.checkNotEmpty(dashboardDescriptor.name!, 'Name')) {
			return;
		}
		const nameAlreadyExists = await this.descriptorNameAlreadyExists(dashboardQualifiedName);
		if (this.allowNameChange && nameAlreadyExists) {
			if (!this.allowOverride) {
				validator.appendError('"' + dashboardQualifiedName + '" already exists');
				return;
			} else if (this.askForOverride) {
				new ConfirmActionWithString(
					'Override existing "' + dashboardDescriptor.name + '"?',
					() => {
						this.doSave(
							this.createMode,
							dashboardDescriptor,
							successCallback,
							nameAlreadyExists,
							oldDashboardName
						);
					},
					null,
					'Override'
				);
				return;
			}
		}
		this.doSave(this.createMode, dashboardDescriptor, successCallback, nameAlreadyExists, oldDashboardName);
	}

	/** Determines whether a dashboard with the given qualified name already exists. */
	protected abstract descriptorNameAlreadyExists(qualifiedName: string): Promise<boolean>;

	/** Template method for performing the service call for saving. */
	protected abstract doSave(
		createMode: boolean,
		dashboardDescriptor: T,
		successCallback: Callback<string>,
		nameAlreadyExists: boolean,
		oldDashboardName?: string
	): void;

	/**
	 * Returns the name of the description field
	 *
	 * @returns The description field name
	 */
	protected abstract getDescriptionFromDescriptor(): string;

	/** Setter for the 'description'-like field of the descriptor. */
	protected abstract setDescriptorDescription(description: string): void;
}
